import { FaCheckCircle } from 'react-icons/fa';
import PrimaryButton from '../global/boton/PrimaryButton';
import CountUp from 'react-countup';
import { useContext } from 'react';
import { GlobalDataContext } from '../../context/context';


const Block_10 = ({ title, text, listsAbout, listsServices, image1, image2, image3, image4, sloganPrincipal }) => {
    const { rpdata } = useContext(GlobalDataContext);

    const counterInfo = [
        {
            title: rpdata?.dbPrincipal?.exprYears ? 'Years Of Experience' : 'Best Service',
            number: rpdata?.dbPrincipal?.exprYears ? rpdata?.dbPrincipal?.exprYears : '100',
            simbolo: rpdata?.dbPrincipal?.exprYears ? '+' : '%',
        },
    ]
    return (
        <div className=" w-full h-auto py-20 flex justify-center text-white">
            <div className="w-4/5 h-auto flex flex-col md:flex-row">
                <div className="w-1/2 relative h-auto lg:h-[550px]">
                    <div className=" w-[285px] h-[79px] flex items-center justify-center relative lg:absolute right-0 lg:right-16 -top-6 md:top-10">
                    {
                                counterInfo.map((items, index) => {
                                    return (
                                        <div key={index} className={`text-center text-third p-5 relative flex justify-center items-center space-x-2`}>
                                            <div>
                                                <CountUp
                                                    end={items.number}
                                                    duration={5}
                                                    className='text-[69px] font-semibold'
                                                />
                                                <span className='text-[69px]'>{items.simbolo}</span>
                                            </div>
                                            <p className='text-[18px] font-semibold'>{items.title}</p>
                                        </div>
                                    )
                                })
                            }
                    </div>
                    <div className="w-[290px] h-[290px] bg3 top-44 -left-10 rounded-full  absolute" />
                    <div

                        className="w-[300px] md:w-[450px] lg:w-[200px] h-[220px] rounded-3xl relative lg:absolute top-0"
                        style={{
                            background: `url("${image1}")`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                        }}
                    ></div>
                    <div

                        className="w-[400px] h-[415px] rounded-3xl hidden md:block absolute bottom-0 right-10"
                        style={{
                            background: `url("${image2}")`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                        }}
                    ></div>
                </div>
                <div className="w-full lg:w-1/2 relative h-full flex flex-col items-start justify-center px-2 lg:px-5">
                    <div className="w-full h-auto mb-3">
                        <div className="w-full h-auto flex items-center justify-start space-x-2 mb-2 text-secondary">
                            <FaCheckCircle
                                fontSize={18}
                            />
                            <h5 className="font-semibold text-lg text-white">A Little About Us</h5>
                        </div>
                        <h2 className='mr-2 font-extrabold text-primary'>{title}</h2>
                    </div>

                    <div className="lg:my-5 my-10">
                        <p>{text}</p>
                    </div>
                    <PrimaryButton />
                </div>
            </div>
        </div>
    )
}


export default Block_10;